import axios from 'axios'

function handleBlob(blob, downloadName) {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', downloadName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export const csvDownload = (url, param, downloadName) => {
  const reqURL = process.env.VUE_APP_BASE + url
  axios.post(reqURL, param).then((response) => {
    const blob = new Blob(['\ufeff' + response.data], { type: 'text/csv,charset=UTF-8' })
    handleBlob(blob, downloadName)
  }).catch((response) => {
    console.log(response)
  })
}
export const pdfGenerate = (url, param, downloadName) => {
  const reqURL = process.env.VUE_APP_BASE + url
  axios.post(reqURL, param, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data])
    handleBlob(blob, downloadName)
  }).catch((response) => {
    console.log(response)
  })
}
export const excelGenerate = (url, param, downloadName) => {
  const reqURL = process.env.VUE_APP_BASE + url
  axios.post(reqURL, param, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
    handleBlob(blob, downloadName)
  }).catch((response) => {
    console.log(response)
  })
}
